import { ApolloClient } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";

import cache from "./cache";


const httpLink = createUploadLink({
    uri: "https://api2.elfe.mp-dev.eu/app-graphql"
    //uri: "https://api.elfe-4u.com/graphql"
});


const authLink = setContext((_, { headers }) => {
    const authToken = localStorage.getItem("authToken");

    if (!authToken) {
        return { headers: { ...headers } };
    }

    return {
        headers: {
            ...headers,
            Accept: "application/json",
            Authorization: `Bearer ${ authToken }`
        }
    };
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache
});


export default client;
